<template>
  <div>
    <v-row
      :style="{
        borderRadius: '10px',
        margin: '4px',
        backgroundColor: $cv('primary') + '20',
      }"
    >
      <v-col cols="auto" md="5" class="py-5">
        <h2>Selecione un metodo de pago</h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" md="3" class="px-5">
        <v-select
          v-model="metodoPago"
          :items="itemsPago"
          density="comfortable"
          label="Seleccionar metodo de pago"
          single-line
          @change="habilitarCompra"
        ></v-select>
      </v-col>
      <v-col
        v-if="metodoPago == 'Tarjeta de Débito'"
        cols="auto"
        md="3"
        class="px-5"
      >
        <v-select
          v-model="metodoDevito"
          :items="itemsDevito"
          density="comfortable"
          label="Seleccionar metodo de pago"
          single-line
          @change="habilitarCompra"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" md="12">
        <v-card
          class=""
          :style="{
            borderRadius: '10px',
            margin: '4px',
            backgroundColor: $cv('primary') + '20',
          }"
          ><h2 class="">
            <b class=""> {{ tituloseccion }}</b>
          </h2>
          <ClienteAduana
            v-if="sourceVentaElementosAduanaSt"
            :cliente="clienteLocal"
          ></ClienteAduana>
          <hr class="" />
          <div v-if="carrito.length == 0" class="carritoMsg__div">
            <p>Sin Productos en el carrito</p>
            <p>Seleccione los Productos que desea comprar</p>
          </div>
          <div class="carrito__div">
            <div v-for="(item, index) in carrito" :key="index">
              <div class="div__vfor">
                <v-col cols="auto" md="2" class="text-center">
                  <h3>
                    {{ item.codigo }}
                  </h3>
                </v-col>
                <v-col cols="auto" md="7">
                  <h3>{{ item.nombre }}</h3>
                </v-col>
                <v-col cols="auto" md="2" class="">
                  <h3>${{ item.precio ? $formatMoney(item.precio) : "" }}</h3>
                </v-col>
                <v-col cols="auto" md="1" class="action__detalle">
                  <v-icon title="Editar" @click="editProductos(item)"
                    >mdi-pencil-box</v-icon
                  >
                  <v-icon title="Eliminar" @click="eliminarProducto(item)"
                    >mdi-trash-can</v-icon
                  >
                </v-col>
              </div>
              <hr v-if="index + 1 < carrito.length" />
            </div>
          </div>

          <div v-show="carrito.length != 0">
            <hr class="" />
            <div class="div_totales">
              <div>
                <h3 class="py-4 px-6 my-0 text-end">
                  Subtotal: $ {{ subtotalSSC ? $formatMoney(subtotalSSC) : "" }}
                </h3>
              </div>
              <div>
                <h3 class="py-4 px-6 my-0 text-end">
                  {{
                    tipogasto === null
                      ? "Gastos de Ingreso: Seleccione Metodo de Pago"
                      : `${tipogasto} ${gasto}`
                  }}
                </h3>
              </div>
              <div>
                <h2 class="py-3 px-6 my-0 text-end">
                  Total: $ {{ resultado ? $formatMoney(resultado) : "" }}
                </h2>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <template>
        <AgregarSaldoCtaCte
          v-if="dialog"
          :item="editedItem"
          :action="accionProducto"
          @terminar="dialog = !dialog"
        />
      </template>
    </v-row>
    <v-row
      class="metodoPado__row"
      :style="{
        borderRadius: '10px',
        margin: '4px',
      }"
    >
      <v-col cols="auto" md="4" class="vcol__comprar">
        <router-link
          to="/adquirir_saldo_cta_cte"
          title="Volver a lista de Productos"
        >
          <v-btn
            density="comfortable"
            color="primary"
            class="font-weight-bold btn__rounded text-none mx-0"
          >
            <v-icon color="white">mdi-cart-outline </v-icon>&nbsp Agregar
            Productos
          </v-btn>
        </router-link>
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="carrito.length != 0" cols="auto" md="4" class="vcol__comprar">
        <router-link
          to="/pedido_finalizado_adquirir_saldo"
          title="Finalizar Pedido"
          :style="{ pointerEvents: habilitaCompra }"
          ><v-btn
            density="comfortable"
            color="primary"
            class="font-weight-bold btn__rounded text-none mx-0"
            :disabled="habilitaCompraBtn"
          >
            <v-icon color="white"></v-icon>
            <span v-if="sourceVentaElementosAduanaSt">
              Vender &nbsp <v-icon color="white">mdi-cart-check</v-icon>
            </span>
            <span v-else>
              Comprar &nbsp <v-icon color="white">mdi-cart-check</v-icon>
            </span>
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import AgregarProductos from "@/views/dashboard/components/secundarios/AgregarProductos.vue/";
import ClienteAduana from "@/views/dashboard/components/secundarios/ClienteAduana.vue";
import AgregarSaldoCtaCte from "@/views/dashboard/components/secundarios/AgregarSaldoCtaCte.vue/";

export default {
  components: {
    AgregarProductos,
    ClienteAduana,
    AgregarSaldoCtaCte,
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
    cartEditPath() {
      if (this.clienteLocal && this.sourceVentaElementosAduanaSt) {
        return {
          name: "elementos_aduana_venta_seleccion",
          params: {
            cliente: this.clienteLocal,
            sourceVentaElementosAduana: this.sourceVentaElementosAduanaSt,
          },
        };
      } else {
        return this.$store.state.cartEditPath;
      }
    },
    ...mapState("aduana", ["clienteLocal", "sourceVentaElementosAduanaSt"]),

    filterItems() {
      if (this.sourceVentaElementosAduanaSt) {
        return this.itemsPago.filter(
          (item) => item !== "B to B (Tranferencia bancaria)"
        );
      } else {
        return this.itemsPago;
      }
    },
  },

  data: (vm) => ({
    routeProductos: "",
    routePedidoFinalizado: "",
    isProduct: true,
    itemsPago: [
      "Boleta Cheque Banco Nación",
      "Boleta Efectivo Banco Nación",
      "B to B (Tranferencia bancaria)",
      "Saldo a Favor",
      "Tarjeta de Débito",
    ],
    itemsDevito: ["Visa", "American Express", "Mastercard"],
    metodoPago: null,
    metodoDevito: null,
    tipogasto: null,
    gasto: null,
    resultado: 0,
    habilitaCompra: "none",
    habilitaCompraBtn: true,
    tituloseccion: "",
    dialog: false,
    editedItem: {},
    accionProducto: "",
    previousPath: "",
    sourceVentaElementosAduana: "",
    subtotalSSC: 0,
  }),

  methods: {
    ...mapMutations("carrito", [
      "vaciarCarrito",
      "setTramite",
      "removeProducto",
      "totalCarritoSSC",
    ]),
    habilitarCompra() {
      if (
        this.metodoPago == "" ||
        this.carrito.length == 0 ||
        this.metodoPago == "Tarjeta de Débito"
      ) {
        this.habilitaCompra = "none";
        this.habilitaCompraBtn = true;
        if (this.metodoPago == "Tarjeta de Débito") this.calculoGastos();
        this.setTramite({ tipogasto: this.tipogasto, gasto: this.gasto });
        return;
      }
      this.calculoGastos();
      console.log(this.gasto);
      this.setTramite({ tipogasto: this.tipogasto, gasto: this.gasto });
      this.habilitaCompra = "";
      this.habilitaCompraBtn = false;
    },
    calculoGastos() {
      if (this.metodoPago) {
        if (this.metodoPago == "Saldo a Favor") {
          this.resultado = this.subtotalSSC;
          this.tipogasto = "Saldo a Favor:";
          this.gasto = "Sin Comisión";
          return "";
        }
        if (this.metodoPago == "Tarjeta de Débito") {
          if (this.metodoDevito != null) {
            this.tipogasto = "Débito:";
            this.gasto = this.metodoDevito;
            this.resultado = this.subtotalSSC;
            this.habilitaCompra = "";
            this.habilitaCompraBtn = false;
          }
          return;
        }
        this.metodoDevito = null;
        if (
          this.metodoPago == "Boleta Cheque Banco Nación" ||
          this.metodoPago == "Boleta Efectivo Banco Nación"
        ) {
          this.tipogasto = "Gastos bancarios:";
          this.gasto = `${(this.subtotalSSC * 5) / 100}`;
          this.resultado = this.subtotalSSC * 1 + this.gasto * 1;
          return (this.habilitaCompra = "");
        } else {
          this.resultado = this.subtotalSSC;
        }
        this.tipogasto = "Gastos de transferencia:";
        this.gasto = `${(this.subtotalSSC * 2.5) / 100}`;
        this.resultado = this.subtotalSSC * 1 + this.gasto * 1;
        return (this.habilitaCompra = "");
      } else if (this.metodoPago == "B to B (Tranferencia bancaria)") {
        this.tipogasto = "Gastos de transferencia:";
        this.gasto = `${(this.subtotalSSC * 2.5) / 100}`;
        this.resultado = this.subtotalSSC * 1 + this.gasto * 1;
        return (this.habilitaCompra = "");
      } else {
        this.tipogasto = null;
        this.gasto = null;
        this.resultado = this.subtotalSSC;
        this.habilitaCompraBtn = true;
        return (this.habilitaCompra = "none");
      }
    },
    editProductos(item) {
      this.editedItem = Object.assign({}, item);
      this.accionProducto = "EDITAR PRODUCTO";
      this.dialog = true;
    },
    eliminarProducto(item) {
      Swal.fire({
        title: `Desea eliminar el Producto?`,
        text: `${item.cantidad} Unidades de ${item.nombre}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeProducto(item);
          Swal.fire({
            title: `Se han Eliminado ${item.cantidad} Unidades de "${item.nombre}"`,
            icon: "success",
          });
        }
      });
    },
    nuevoSub() {
      this.subtotalSSC = 0;
      this.carrito.forEach((item) => {
        this.subtotalSSC = this.subtotalSSC * 1 + item.precio * 1;
      });
      if (isNaN(this.gasto)) this.resultado = this.subtotalSSC;
      this.resultado = this.subtotalSSC * 1 + this.gasto * 1;
    },
  },

  created() {
    const previousPath = this.$route.params.previousPath;
  },
  mounted() {
    this.tituloseccion = "Resumen de pedido";
    this.nuevoSub();
    this.resultado = this.subtotalSSC;
  },
  watch: {
    carrito() {
      // this.habilitarCompra();
      this.nuevoSub();
    },
  },
};
</script>

<style>
h2 {
  padding: 1rem 2rem;
}
.carritoMsg__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 9rem;
  font-weight: bold;
}
.div__vfor {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.carrito__div {
  overflow: auto;
  max-height: 28.5rem;
}
.metodoPado__row {
  display: flex;
  align-items: center;
  border-radius: 1rem;
}
.div_totales {
  display: flex;
  justify-content: space-between;
}
.vcol__comprar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.routerLink_Comprar {
  background-color: #011f5b;
  color: white !important;
  padding: 1rem 4rem;
  text-decoration: none;
  border-radius: 0.5rem;
}
.action__detalle {
  display: flex;
  justify-content: space-around;
}
</style>
